import { Layout, Card } from 'antd';
import './App.css';
import Stat from './Stat';
import History from "./History";
import { useState, useEffect } from 'react';

const { Header, Content, Footer } = Layout;

const tabListTemplate = [
  {
    key: 'stat',
    tab: '正在上传',
  },
  {
    key: 'history',
    tab: '已上传',
  },
];

function App() {
  const [currentTab, setCurrentTab] = useState('stat');

  const [dataSources, setDataSources] = useState({
    stat: [],
    history: [],
  });

  const [tabList, setTabList] = useState(tabListTemplate);

  useEffect(() => {
    const req1 = fetch("https://us-lv.hguandl.com/history")
      .then((res) => res.json());

    const req2 = fetch("https://us-lv.hguandl.com/stat")
      .then((res) => res.json());

    Promise.all([req1, req2]).then(([req1Data, req2Data]) => {
      tabList[0].tab = `正在上传(${req2Data.uploads.length || 0})`;
      tabList[1].tab = `已上传(${req1Data.length})`;
      setTabList(tabList);
      setDataSources({
        stat: req2Data,
        history: req1Data,
      });
    });
  }, [currentTab]);

  const tabContent = (currentTab: string) => {
    switch (currentTab) {
      case 'stat':
        return <Stat dataSource={dataSources.stat}/>;
      case 'history':
        return <History dataSource={dataSources.history} />;
      default:
        return <></>;
    }
  };

  return (
    <Layout>
      <Header >
        <div className='logo'>
          Bili Live
        </div>
      </Header>

      <Content style={{ padding: '2em 3em' }}>
        <Card
          tabList={tabList}
          activeTabKey={currentTab}
          onTabChange={(key) => { setCurrentTab(key); }}
        >
          {tabContent(currentTab)}
        </Card>
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        Bili Live &copy; 2022 by Hao Guan
      </Footer>
    </Layout>
  );
}

export default App;
