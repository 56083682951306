import { Table, Tag, Space } from "antd";
import moment from "moment";
import prettyBytes from 'pretty-bytes';

const columns = [
  {
    title: "时间",
    dataIndex: "finished_at",
    key: "finished_at",
    render: (ts: number) => moment.unix(ts).format("YYYY-MM-DD HH:mm:ss")
  },
  {
    title: "录像文件",
    dataIndex: "relative_path",
    key: "relative_path",
  },
  {
    title: "文件大小",
    dataIndex: "file_size",
    key: "file_size",
    render: (size: number) => prettyBytes(size)
  },
  {
    title: "aid",
    dataIndex: "avid",
    key: "aid",
    render: (avid: number) => `av${avid}`
  }
];

const History = (props: {dataSource: any}) => {
  const { dataSource } = props;

  return (
    <>
      <Table columns={columns} dataSource={dataSource} />
    </>
  )
};

export default History;