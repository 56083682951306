import { Table, Tag, Space } from "antd";
import moment from "moment";

const Stat = (props: {dataSource: any}) => {
  const { dataSource } = props;

  const columns = [
    {
      title: "时间",
      dataIndex: "created_at",
      key: "created_at",
      render: (ts: number) => moment.unix(ts).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "录像文件",
      dataIndex: "relative_path",
      key: "relative_path",
    },
    {
      title: "状态",
      key: "upload_status",
      render: (text: any, record: any) => {
        if (record.event_id === dataSource.current) {
          const progress = (dataSource.uploaded / record.file_size * 100.0).toFixed(2);
          return `正在上传: ${progress}%`;
        }
        return "等待上传";
      }
    }
  ];

  return (
    <>
      <Table columns={columns} dataSource={dataSource.uploads} />
    </>
  )
};

export default Stat;